table.renderPreview {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    text-align: left;
    padding: 0.5rem;
    font-size: 16pt;
  }

  th.headerValue {
    font-size: 12pt;
  }

  tr:nth-child(even) {
    background-color: #ececec;
  }

  td {
    padding: 0.5rem;
  }

  p {
    margin: 3px;
  }
}

.tableWrapper {
  border: 1px solid #d1d1d1;
  margin-bottom: 20px;
}

.sections {
  column-count: 2;
}

.renderContainer {
  padding-top: 40px;
}
