.container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.dirtyContainer {
  border: 2px solid #9c27b0;
  margin: -2px;
}

.formLabel {
  font-size: 0.75rem !important;
  margin-left: 0.90rem;
}

.dirtyFormLabel {
  color: #9c27b0 !important;
}
