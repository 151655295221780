
.logDataCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.logDataRow {
  padding: 0.5rem 0;
}

.indent {
  margin-left: 1rem;
}

.logTable {
  td {
    padding-right: 0.5rem;
  }
  td.key {
    vertical-align: top;
    text-align: right;
  }
  td.innerKey {
    vertical-align: top;
    font-style: italic;
  }
}
